import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import PopupMessage from './PopupMessage';
import './Login.css';
import logo from '../assets/logoapp.png';
import footerImage from '../assets/logos.png';

const Login = () => {
  const [cpf, setCpf] = useState('');
  const [matricula, setMatricula] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const cpfUnmasked = cpf.replace(/\D/g, ''); // Remove a máscara do CPF

    try {
      const response = await fetch('https://rh.sagresrg.com.br/usuarios', {
        method: 'GET',
        headers: {
          'Authorization': 'Basic d37b2b5124b3203aac517b47c1d33a475a075fd0c797347b22893e9c2303',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.status === 'success' && data.data.length > 0) {
        const user = data.data.find(
          (user) => user.cpf === cpfUnmasked && user.matricula === matricula
        );

        if (user) {
          localStorage.setItem('userName', user.name);
          localStorage.setItem('token', 'seuTokenAqui');
          localStorage.setItem('colaboradorId', user.id);
          localStorage.setItem('setoresId', user.setores_id); // Salva o setores_id
          localStorage.setItem('locaisId', user.locais_id); // Salva o locais_id
          localStorage.setItem('empresa', user.empresa); // Salva o campo empresa
          navigate('/home');
        } else {
          setErrorMessage('Credenciais incorretas');
        }
      } else {
        setErrorMessage('Nenhum usuário encontrado ou erro na resposta');
      }
    } catch (error) {
      setErrorMessage('Erro na conexão com o servidor');
    }
  };

  return (
    <div className="login-container">
      {errorMessage && <PopupMessage message={errorMessage} onClose={() => setErrorMessage(null)} />}
      <div className="login-card">
        <img src={logo} alt="Logo do App" className="app-logo" />
        <h2 className="login-title">Login</h2>
        <form onSubmit={handleLogin} className="login-form">
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            className="login-input"
            placeholder="CPF"
            required
          />
          <input
            type="text"
            placeholder="Matrícula"
            value={matricula}
            onChange={(e) => setMatricula(e.target.value)}
            className="login-input"
            required
          />
          <button type="submit" className="login-button">Entrar</button>
        </form>
        <img src={footerImage} alt="Rodapé" className="footer-image" />
      </div>
    </div>
  );
};

export default Login;
