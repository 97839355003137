import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaUserEdit, FaSignOutAlt } from 'react-icons/fa'; // Ícones do react-icons
import './Home.css';

function Home() {
  const [username, setUsername] = useState('');
  const [ideas, setIdeas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const name = localStorage.getItem('userName');
    const colaboradorId = localStorage.getItem('colaboradorId');

    if (!token || !colaboradorId) {
      navigate('/login');
    } else {
      setUsername(name || 'Usuário');
      fetchIdeas(parseInt(colaboradorId, 10));
    }
  }, [navigate]);

  const fetchIdeas = async (colaboradorId) => {
    try {
      const response = await fetch('https://rh.sagresrg.com.br/ideias', {
        method: 'GET',
        headers: {
          'Authorization': 'Basic d37b2b5124b3203aac517b47c1d33a475a075fd0c797347b22893e9c2303',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.status === 'success') {
        const userIdeas = data.data.filter((idea) => idea.colaboradores_id === colaboradorId);
        setIdeas(userIdeas);
      } else {
        console.error('Erro ao buscar ideias:', data);
      }
    } catch (error) {
      console.error('Erro na chamada da API de ideias:', error);
    }
  };

  const getStatusText = (statusId) => {
    switch (statusId) {
      case 1:
        return 'Pendente';
      case 2:
        return 'Classificada';
      case 3:
        return 'Finalizada';
      default:
        return 'Desconhecido';
    }
  };

  const getStatusClass = (statusId) => {
    switch (statusId) {
      case 1:
        return 'status-pendente';
      case 2:
        return 'status-classificada';
      case 3:
        return 'status-finalizada';
      default:
        return 'status-desconhecido';
    }
  };

  const getStatusMessage = (statusId) => {
    switch (statusId) {
      case 1:
        return 'Sua ideia foi enviada para o RH e para o gestor responsável avaliar';
      case 2:
        return 'Sua ideia já foi avaliada pelo gestor e está com o RH para avaliação final';
      case 3:
        return 'Sua ideia já foi avaliada pelo RH e pelo gestor responsável';
      default:
        return '';
    }
  };

  const handleNovaIdeiaClick = () => {
    navigate('/nova-ideia');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('colaboradorId');
    navigate('/login');
  };

  return (
    <div className="home-container">
      <div className="welcome-card">
        <h1>Bem-vindo, {username}!</h1>
        <div className="icon-buttons">
          <button onClick={handleNovaIdeiaClick} className="icon-card">
            <FaPlus /> {/* Ícone de Nova Ideia */}
            <p>Nova Ideia</p>
          </button>
          <button className="icon-card">
            <FaUserEdit /> {/* Ícone de Perfil */}
            <p>Perfil</p>
          </button>
          <button onClick={handleLogout} className="icon-card">
            <FaSignOutAlt /> {/* Ícone de Sair */}
            <p>Sair</p>
          </button>
        </div>
      </div>

      <div className="ideas-list">
        <h2>Lista de Ideias</h2>
        <table>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {ideas.length > 0 ? (
              ideas.map((idea) => (
                <tr key={idea.id} className="idea-row">
                  <td>{idea.descricao}</td>
                  <td>
                    <span
                      className={`status-label ${getStatusClass(idea.status_ideias_id)}`}
                      title={getStatusMessage(idea.status_ideias_id)}
                    >
                      {getStatusText(idea.status_ideias_id)}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">Nenhuma ideia encontrada para este colaborador.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Home;
