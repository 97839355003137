import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './NovaIdeiaForm.css';

function NovaIdeiaForm() {
  const [colaboradorNome, setColaboradorNome] = useState('');
  const [colaboradorId, setColaboradorId] = useState('');
  const [descricao, setDescricao] = useState('');
  const [tipoIdeia, setTipoIdeia] = useState('');
  const [tiposIdeias, setTiposIdeias] = useState([]);
  const [gestorId, setGestorId] = useState(null); // Armazena o ID do gestor
  const navigate = useNavigate();

  useEffect(() => {
    // Verificação de autenticação
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('colaboradorId');
    const nome = localStorage.getItem('userName');

    if (!token || !id) {
      navigate('/login');
    } else {
      setColaboradorNome(nome || 'Usuário');
      setColaboradorId(id);
      fetchTiposIdeias();
      fetchGestorId(); // Busca o gestor relacionado ao setor
    }
  }, [navigate]);

  // Função para buscar tipos de ideias
  const fetchTiposIdeias = async () => {
    try {
      const response = await fetch('https://rh.sagresrg.com.br/tipoideias', {
        method: 'GET',
        headers: {
          'Authorization': 'Basic d37b2b5124b3203aac517b47c1d33a475a075fd0c797347b22893e9c2303',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (data.status === 'success') {
        setTiposIdeias(data.data);
      } else {
        console.error('Erro ao carregar tipos de ideias:', data.message);
      }
    } catch (error) {
      console.error('Erro na requisição de tipos de ideias:', error);
    }
  };

  // Função para buscar o gestor associado ao setor do usuário
  const fetchGestorId = async () => {
    const setoresId = localStorage.getItem('setoresId');
    try {
      const response = await fetch('https://rh.sagresrg.com.br/setores', {
        method: 'GET',
        headers: {
          'Authorization': 'Basic d37b2b5124b3203aac517b47c1d33a475a075fd0c797347b22893e9c2303',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (data.status === 'success') {
        // Busca o setor correspondente ao setoresId e obtém o ID do gestor
        const setor = data.data.find((setor) => setor.id === parseInt(setoresId, 10));
        if (setor) {
          setGestorId(setor.gestor); // Define o ID do gestor
        }
      } else {
        console.error('Erro ao carregar setores:', data.message);
      }
    } catch (error) {
      console.error('Erro na requisição de setores:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const novaIdeiaPayload = {
      aplicavel: '',
      colaboradores_id: colaboradorId,
      descricao: descricao,
      id: null,
      locais_id: localStorage.getItem('locaisId'),
      motivo: '',
      nota: '',
      setores_id: localStorage.getItem('setoresId'),
      empresa: localStorage.getItem('empresa'),
      gestor: gestorId, // Inclui o ID do gestor no payload
      status_ideias_id: 1,
      tipos_ideias_id: tipoIdeia,
    };

    try {
      const response = await fetch('https://rh.sagresrg.com.br/ideias', {
        method: 'POST',
        headers: {
          'Authorization': 'Basic d37b2b5124b3203aac517b47c1d33a475a075fd0c797347b22893e9c2303',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(novaIdeiaPayload),
      });

      const data = await response.json();
      if (data.status === 'success') {
        alert('Ideia enviada com sucesso!');
        navigate('/home');
      } else {
        alert('Erro ao enviar ideia: ' + (data.message || 'Erro desconhecido no servidor.'));
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      alert('Erro ao enviar ideia. Tente novamente.');
    }
  };

  const handleBack = () => {
    navigate(-1); // Volta para a página anterior
  };

  return (
    <div className="nova-ideia-form-container">
      <h2>Nova Ideia</h2>
      <button onClick={handleBack} className="back-button">Voltar</button>
      <form onSubmit={handleSubmit} className="nova-ideia-form">
        <div className="form-group">
          <label>Colaborador</label>
          <input
            type="text"
            value={colaboradorNome}
            readOnly
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Ideia</label>
          <textarea
            className="form-control textarea-ideia"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            placeholder="Descreva sua ideia..."
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Tipo</label>
          <select
            value={tipoIdeia}
            onChange={(e) => setTipoIdeia(e.target.value)}
            required
            className="form-control"
          >
            <option value="">Selecione um tipo</option>
            {tiposIdeias.map((tipo) => (
              <option key={tipo.id} value={tipo.id}>
                {tipo.nome}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="submit-button">
          Enviar Ideia
        </button>
      </form>
    </div>
  );
}

export default NovaIdeiaForm;
