import React from 'react';
import './PopupMessage.css';

const PopupMessage = ({ message, onClose }) => (
  <div className="popup-overlay">
    <div className="popup-message">
      <p>{message}</p>
      <button onClick={onClose}>Fechar</button>
    </div>
  </div>
);

export default PopupMessage;
